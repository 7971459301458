(function($) {

  var isoOptions = {
      layoutMode: 'packery',
      itemSelector: '.grid-item',
      stamp: '.stamp'
  };


	var $grid = $('.grid').isotope(isoOptions);
  var iso = $grid.data('isotope');

  // layout Isotope after each image loads
$grid.imagesLoaded().progress( function() {
  $grid.isotope('layout');
});

function isoremove() { 
  if ($('header .nav-primary').css('position') === 'absolute') {
    $grid.isotope(isoOptions); 
  }
  else {
    $grid.isotope('destroy');
  }
}
 
// init Infinite Scroll

var nextURL;

function updateNextURL( doc ) {
  nextURL = $( doc ).find('.next-group a').attr('href');
}
// get initial nextURL
updateNextURL( document );

// init Infinite Scroll
var $container = $grid.infiniteScroll({
  // Infinite Scroll options...
   path: function() {
    return nextURL;
  },
  append: '.grid-item-link',
  outlayer: iso,
  scrollThreshold: 1000,
  history: false,
  status: '.page-load-status',
});

// update nextURL on page load
$container.on( 'load.infiniteScroll', function( event, response ) {
  updateNextURL( response );
});

$(window).load( function(){
  isoremove();  
});

$(window).resize(isoremove);
	
})(jQuery);
