(function($) {

/* MATCH HEIGHT INITIALIZE */

    /*$('.development .match-height').matchHeight();
    function skillheight() {
        var skill = $('.development .skills .skill');
        if ($('.page-template-template-dev h3').css('padding-bottom') === '20px') {
            $(skill).matchHeight({byRow: false});
        }
        else {
            $(skill).matchHeight({remove: true});
        }
    }
    skillheight();
    $(window).resize(skillheight); 
    */
})(jQuery);