(function($) {

/* NAV POSITION AT MOBILE */

var nav = $('header .nav-primary');

function addmobilescroll() {
  $(nav).addClass('mobile-scroll');
}
function remmobilescroll() {
  $(nav).removeClass('mobile-scroll');
}  

$(window).scroll(function() {
  if ($(nav).css('position') === 'fixed') { 
    var top = 100;
    if ($(window).scrollTop() >= top) {
      addmobilescroll();
    }
    else {
      remmobilescroll();
    }
  }
});

$(window).resize(remmobilescroll);

/* Move Mobile Menu to Top of Page when Contact Form Open */

$('.nav-primary #main-nav #contact a').click(function() {
  if ($(nav).css('position') === 'fixed') { 
    addmobilescroll();
  }
});

/* Scroll To Top */

    $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $('.scrollToTop').fadeIn();
            $('#back-design').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
            $('#back-design').fadeOut();
        }
    });

    //Click event to scroll to top
    $('.scrollToTop').click(function(){
        $('html, body').animate({scrollTop : 0},800);
        return false;
    });


/* KEEP DESIGN NAV MENU ITEM HIGHLIGHTED */

if (window.location.href.indexOf("/design") > -1) {
    $('.nav-primary #main-nav ul.nav li a:contains("Design")').closest('li').addClass('current-menu-item');
}

if (window.location.href.indexOf("/dev") > -1) {
    $('.nav-primary #main-nav ul.nav li a:contains("Development")').closest('li').addClass('current-menu-item');
}

/* LOGO SIZE BASED ON COLUMN */

function logowidth() {
  if ($('header .nav-primary').css('position') === 'absolute') { 
    var introwidth = $('.intro .sizer').innerWidth();
    $('header .logo').innerWidth(introwidth);
  }
  else {
    $('header .logo').css('width','');
  }  
}

logowidth();
$(window).resize(logowidth);  

/* DESIGN INTRO IMAGE HEIGHT BASED ON INTRO CONTENT */

function getdesheight() { 
  if ($('.page-template-template-designs header .nav-primary').css('position') === 'absolute') {
      var desheighta2 = $('.page-template-template-designs .intro .grid-item-image .des-height .category-name').height() + $('.page-template-template-designs .intro .grid-item-image .des-height p').height() + 200;
      $('.page-template-template-designs .intro .grid-image').height(desheighta2);
      $('.page-template-template-designs .intro .collapse' ).addClass('show').attr('aria-expanded','true').css('height','');
      $('.page-template-template-designs .intro .opener' ).attr('aria-expanded','true'); 
  }
  else {
      $('.page-template-template-designs .intro .grid-image').css('height','');
      $('.page-template-template-designs .intro .collapse' ).removeClass('show').attr('aria-expanded','false');
      $('.page-template-template-designs .intro .opener' ).attr('aria-expanded','false');
  }
}

getdesheight();

var windowWidthA = $(window).width();

$(window).on('resize', function () {
  // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
  if ($(window).width() !== windowWidthA) {
  // Update the window width for next time
  windowWidthA = $(window).width();
  if ($('.page-template-template-designs header .nav-primary').css('position') === 'absolute') {
      var desheightb2 = $('.page-template-template-designs .intro .grid-item-image .des-height .category-name').height() + $('.page-template-template-designs .intro .grid-item-image .des-height p').height() + 200;
      $('.page-template-template-designs .intro .grid-image').height(desheightb2);
      $('.page-template-template-designs .intro .collapse' ).addClass('show').attr('aria-expanded','true').css('height','');
      $('.page-template-template-designs .intro .opener' ).attr('aria-expanded','true');
  }
  else {
      $('.page-template-template-designs .intro .grid-image').css('height','');
      $('.page-template-template-designs .intro .collapse' ).removeClass('show').attr('aria-expanded','false');
      $('.page-template-template-designs .intro .opener' ).attr('aria-expanded','false');  
  }
  }

});

/* SINGLE DESIGN INTRO IMAGE HEIGHT BASED ON INTRO CONTENT */

function getdessheight() { 
  if ($('.single-designs header .nav-primary').css('position') === 'absolute') {
      var dessheighta = $('.single-designs .intro .grid-item-image .des-height .category-name').outerHeight() + $('.single-designs .intro .grid-item-image .des-height .project-roles').outerHeight() + $('.single-designs .intro .grid-item-image .des-height .urls').outerHeight() + 250;
      $('.single-designs .intro .grid-image').height(dessheighta);
      $('.single-designs .intro .collapse' ).addClass('show').attr('aria-expanded','true').css('height','');
      $('.single-designs .intro .opener' ).attr('aria-expanded','true');
  }
  else {
    $('.single-designs .intro .grid-image').css('height','');
    $('.single-designs .intro .collapse' ).removeClass('show').attr('aria-expanded','false');
    $('.single-designs .intro .opener' ).attr('aria-expanded','false');
  }
}    

getdessheight();

var windowWidthB = $(window).width();

$(window).on('resize', function () {
  // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
  if ($(window).width() !== windowWidthB) {
  // Update the window width for next time
  windowWidthB = $(window).width();
  if ($('.single-designs header .nav-primary').css('position') === 'absolute') {
    var dessheightb = $('.single-designs .intro .grid-item-image .des-height .category-name').outerHeight() + $('.single-designs .intro .grid-item-image .des-height .project-roles').outerHeight() + $('.single-designs .intro .grid-item-image .des-height .urls').outerHeight() + 250;
    $('.single-designs .intro .grid-image').height(dessheightb);
    $('.single-designs .intro .collapse' ).addClass('show').attr('aria-expanded','true').css('height','');
    $('.single-designs .intro .opener' ).attr('aria-expanded','true');
  }
  else {
    $('.single-designs .intro .grid-image').css('height','');
    $('.single-designs .intro .collapse' ).removeClass('show').attr('aria-expanded','false');
    $('.single-designs .intro .opener' ).attr('aria-expanded','false');
  }  
}
  
});

/* SINGLE DESIGN LIGHTBOX*/

$('.single-designs .designs a.modal-toggle').click(function() {
    var imageSrc = $(this).find('.grid-item-image img').attr('src');
    var item = $('#imageModal #modal-carousel .carousel-item');
    
    $(item).each(function() {
        if ($(this).find('img').attr('src') === imageSrc) {
            $(this).addClass('active');
            $(item).not(this).removeClass('active');
        } 
     });   

});

/* DEV INTRO HEIGHT BASED ON CONTENT 

var resizeTimer;

$(window).on('resize', function(e) {

  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {

    $('.development .match-height').css('height','auto');
    $('.development .match-height').matchHeight();
            
  }, 200);

});*/

})(jQuery);